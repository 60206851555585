<style lang="scss">

</style>

<template>
    <section class="radio-comp mb-10 animate__animated animate__fadeIn animate__fast theme-purple-background">
        <app-header v-if="!isParcVersion" />

        <playing-banner v-if="station.details"
                        @mute="mute($event)"
                        @pause="pause($event)"
                        @stop="stop"
                        @update-volume="setVolume($event)">
        </playing-banner>

        <visual-search @play="play($event)"></visual-search>

        <favourites v-if="!isParcVersion" @play="play($event)"/>

        <audio ref="audioPlayer" style="display: none" />
    </section>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import {Howl, Howler} from 'howler';
  import { mapState } from 'vuex';
  import RadioService from '../services/radioService';
  import AppHeader from './AppHeader.vue';
  import PlayingBanner from './PlayingBanner';
  import Favourites from './Favourites';
  import VisualSearch from '@/components/search/VisualSearch.vue';
  import Hls from 'hls.js';

  export default {
    props: [''],
    components: {
      AppHeader,
      PlayingBanner,
      Favourites,
      VisualSearch,
    },
    computed: {
      ...mapState({
        favourites: state => state.favourites.favourites,
        station: state => state.radio.station,// currently playing channel
        stations: state => state.radio.stations,
        isAndroid: state => state.isAndroid,
      }),
      isParcVersion() {
        return window.location.hostname === 'parc.purple-radio.com' || window.location.hostname === 'localhost' || window.location.hostname === '10.0.2.2';
      },
    },
    data() {
      return {
        filters: {
          name: ''
        },
        radio: null,
      };
    },
    beforeDestroy() {
      this.stop();
    },
    mounted() {
      this.$store.commit('favourites/SET_FAVOURITES_FROM_LOCAL');
      this.setRegionByQuery();
      this.setUpRadio();
    },
    methods: {
      setUpRadio(){
        if (Howler) {
          Howler.unload();
        }

        this.radio = new Howl({
          src: this.stations,
          format: ['mp3'],
        });

        this.setDefaultRadio();
      },
      setUpHls(station, streamUrl) {
        const hls = new Hls();
        let audioPlayer = this.$refs.audioPlayer;

        if (Hls.isSupported()) {
          hls.loadSource(streamUrl);
          hls.attachMedia(audioPlayer);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            audioPlayer.play();
            // Apply the current mute state
            audioPlayer.muted = this.station.muted;
          });
          audioPlayer.addEventListener('error', () => {
            console.error('Hls load error');
            this.$toasted.error('There is a problem with this station, please choose another');
          });
          this.$store.commit('radio/SET_STATION_PLAYING', {
            paused: false,
          });
        } else if (audioPlayer.canPlayType('application/vnd.apple.mpegurl')) {
          audioPlayer.src = streamUrl;
          audioPlayer.muted = this.station.muted; // Apply mute state
          this.$store.commit('radio/SET_STATION_PLAYING', {
            paused: false,
          });
        } else {
          this.$toasted.error('There is a problem with this station, please choose another');
          console.error('HLS.js is not supported and native HLS is not supported.');
        }
      },
      setRegionByQuery() {
        const urlParams = new URLSearchParams(window.location.search);
        const region = urlParams.get('region');
        if (region) {
          this.$store.dispatch('radio/changeRegion', region);
        }
      },
      setDefaultRadio(){
        if(this.favourites[0]){
          this.play(this.favourites[0], true);
        } else {
          this.play(this.stations[0], true); // default radio 1
        }
      },
      play(station, init){
        this.stop();

        let uri = station.uri;

        const isHls = uri.endsWith('.m3u8')

        if (isHls) {
          console.log('>>> is Hls')
          this.setUpHls(station, uri);

        } else {
          console.log('>>> is regular')
          // Workaround for planetradio radio stations
          if(station.uri.includes("planetradio")){
            const unixTimestamp = Math.floor(Date.now() / 1000);
            uri = uri + `?aw_0_1st.bauer_loggedin=true&aw_0_1st.skey=${unixTimestamp}`;
          }

          this.$store.commit('radio/SET_STATION_PLAYING', new Howl({
            src: uri,
            html5: true,
            format: ['mp3', 'aac']
          }));

          this.station.playing.on('loaderror', () => {
            this.$toasted.error('There is a problem with this station, please choose another');
            console.log('load error');
          });

          // Play Howl player
          this.station.playing.play();
        }

        if (init) {
          this.$store.commit('radio/SET_STATION_PLAYING_PAUSED', true);
        } else {
          this.$store.commit('radio/SET_STATION_PLAYING_PAUSED', false);
        }

        this.$store.commit('radio/SET_STATION_DETAILS', station);
      },
      stop() {
        if(this.station.playing){
          this.$store.commit('radio/SET_STATION_PLAYING_PAUSED', true);
          const isHls = this.station && this.station.details && this.station.details.uri.endsWith('.m3u8');
          if (isHls) {
            this.$refs.audioPlayer.pause();
          } else {
            this.station.playing.unload();
          }
        }
      },
      pause(value){
        this.$store.commit('radio/SET_STATION_PLAYING_PAUSED', value);

        if(value) {
          this.stop();
        } else {
          this.play(this.station.details); // replay as radio technically does not pause
        }
      },
      getStationsByName(name){
        RadioService.getStationsByName(name).then((res) => {
          console.log('got radio stations', res);
          this.stations = res.data.radios;
        }).catch((err) => {
          console.log('could not get radio stations', err);
        })
      },
      setVolume(number){
        const isHls = this.station.details.uri.endsWith('.m3u8')
        if (isHls) {
          this.$refs.audioPlayer.volume = number;
        } else {
          this.station.playing.volume(number);
        }
      },
      mute(value) {
        const isHls = this.station.details.uri.endsWith('.m3u8');

        if (isHls) {
          // For HLS streams, mute the audio element directly
          this.$refs.audioPlayer.muted = value;
        } else if (this.station.playing && typeof this.station.playing.mute === 'function') {
          // For Howler.js streams, use the mute method
          this.station.playing.mute(value);
        }

        // Update the mute state in Vuex
        this.$store.commit('radio/SET_STATION_MUTED', value);
      },
      sendLog(description, meta = null) {
        this.$store.dispatch('radio/sendLog', {
          description: description,
          meta: meta
        });
      },
    },
  };
</script>
