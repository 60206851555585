<script>
import {mapState} from "vuex";

export default {
  ...mapState({
    station: state => state.radio.station,
  }),
  methods: {
    closeApp() {
      try {
        this.$emit('stop');
        this.$modal.hide('close-modal');
        this.sendLog('Quit Radio App');
        // eslint-disable-next-line no-undef
        Android.quit();
      } catch (e) {
        console.error('Error closing app: ', e);
      }
    },
    sendLog(description, meta = null) {
      this.$store.dispatch('radio/sendLog', {
        description: description,
        meta: meta
      });
    },
  },
}
</script>

<template>
  <div>
    <button class="exit-button" @click="$modal.show('close-modal')">
      Exit
    </button>

    <modal class="close-modal" name="close-modal">
      <h1 class="mb-8">Are you sure you want to close the radio?</h1>
      <section class="d-flex full-width justify-center">
        <v-btn x-large class="mr-6 btn-green" @click="closeApp()">Yes</v-btn>
        <v-btn x-large class="btn-red" @click="$modal.hide('close-modal')">No</v-btn>
      </section>
    </modal>
  </div>
</template>

<style lang="scss">
.exit-button {
  background-color: #6a0dad;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.exit-button:active {
  background-color: #4a0072;
  transform: scale(0.98);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.exit-button {
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.close-modal {
  .v--modal-box {
    padding: 25px;
    background-color: $purple;
    border-radius: 2em;
    text-align: center;

    h1, h5 {
      color: white;
    }
  }

  .v--modal-background-click {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
</style>
