<template>
  <v-app class="light-theme">
    <v-main class="theme-background theme-purple-background">
      <v-container fluid class="no-gutters pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import '@/assets/styles/styles.scss';

  export default {
    name: 'App',
    components: {

    },
    data: () => ({
      //
    }),
    mounted() {

    }
  };
</script>
